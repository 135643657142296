/*
usage
  import greCAPTCHA from './plugins/gmaps';
  const self.hrecaptch = await reCAPTCHA();
*/

const CALLBACK_NAME = 'greCAPTCHACallback';
let initialized = !!window.grecaptcha;
let resolveInitPromise;
let rejectInitPromise;

const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});

export default function init(k) {
  if (initialized) return initPromise;
  initialized = true;
  window[CALLBACK_NAME] = () => resolveInitPromise(window.grecaptcha);
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.src = `https://www.google.com/recaptcha/api.js?render=${k}&onload=${CALLBACK_NAME}`;
  script.onerror = rejectInitPromise;
  document.querySelector('head').appendChild(script);
  return initPromise;
}